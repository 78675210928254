import React, { Component, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { constants as c } from '../../utils/constants';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import AppSelector from '../../components/AppSelector/AppSelector';

import apps from '../../utils/appDirectory';
import UsersList from '../UsersList/UsersList';
import Profile from '../Profile/Profile';
import AddUsers from '../AddUsers/AddUsers';
import './Dashboard.scss';
import HeaderContainer from '../../containers/HeaderContainer/HeaderContainer';
import colors from '../../colors';
import styled from 'styled-components';
import { createBrowserHistory } from 'history';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

const StyledHeaderContainer = styled.div`
  background-color: ${colors.BRAND_NAVY};
  height: 45px;
  z-index: 50;
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
`;

const Container = styled.div`
  padding: 10px;
  overflow: auto;
 
`;

const StyledH2 = styled.h2`
  font-size: revert;
  margin-top: revert;
  margin-bottom: revert;
  font-weight: revert;
  line-height: revert;
  padding-left: 5px;
`;

const Dashboard = (props) => {
  const [state, setState] = useState({
    navbarOpen: false,
    redirectTo: null,
    userNames: {}
  });
  const ldclient = useLDClient();
  const flags = useFlags();
  const [showProfile, setShowProfile] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFeedbackAdmin, setIsFeedbackAdmin] = useState(false);
  const [history, setHistory] = useState(createBrowserHistory());
  const [allowedApps, setAllowedApps] = useState([]);
  let userGroups = [];

  const { authData, showToast, signOut } = props;
  const { redirectTo, userNames } = state;
  const nonDashboardApps = ['odysseyPortal', 'profile', 'portal'];

  useEffect(async () => {
    await handleRedirects();
  }, []);

  // Temporarily turn off feature flags for individual users
  // useEffect(async () => {
  //   const session = await Auth.currentSession();
  //   const userName = session.idToken.payload['name'];
  //   const userEmail = session.idToken.payload['email'];
  //   ldclient.identify({ key: userEmail, name: userName, email: userEmail });
  // }, []);

  useEffect(async () => {
    const session = await Auth.currentSession();
    userGroups = session.idToken.payload['cognito:groups'];

    const allowedApps = buildAllowedAppsSet();
    const allowedAppsData = buildAllowedAppsData(allowedApps);
    setAllowedApps(allowedAppsData);
  }, []);

  useEffect(async () => {
    setShowProfile(flags.odysseyPortalProfile);
  }, [flags]);

  const buildAllowedAppsSet = () => {
    const allowedApps = new Set();

    if (userGroups) {
      userGroups.forEach((group) => {
        const groupParts = group.split('_');
        const numParts = groupParts.length;
        let groupApp, groupRole;

        if (numParts > 2) {
          groupApp = groupParts[2];
          groupRole = groupParts[3];
        } else {
          groupApp = groupParts[0];
          groupRole = groupParts[1];
        }

        if (nonDashboardApps.indexOf(groupApp) === -1) {
          allowedApps.add(groupApp);
        }

        if (groupApp === 'feedback' && groupRole === 'admin') {
          setIsFeedbackAdmin(true);
        }

        if (groupApp === 'odysseyPortal' && groupRole === 'admin') {
          setIsAdmin(true);
          // if user is an Odyssey Portal admin, allow all apps
          allowedApps.add('allowAll');
        }
      });
    }

    return allowedApps;
  };

  const buildAllowedAppsData = (allowedApps) => {
    const applications = [];
    if (allowedApps.has('allowAll')) {
      Object.values(apps).forEach((application) => {
        if (nonDashboardApps.indexOf(application.key) === -1) {
          applications.push(application);
        }
      });
    } else {
      allowedApps.forEach((app) => {
        const application = apps[app];
        applications.push(application);
      });
    }
    return applications;
  };

  // eslint-disable-next-line react/sort-comp
  const handleRedirects = async () => {
    let redirectUrl = localStorage.getItem('redirectTo'); 

    const session = await Auth.currentSession();
    userGroups = session.idToken.payload['cognito:groups'];

    const allowedApps = buildAllowedAppsSet();
    const allowedAppsData = buildAllowedAppsData(allowedApps);

    setAllowedApps(allowedAppsData);

    if (redirectUrl) {
      try{
        redirectUrl = JSON.parse(redirectUrl)
        localStorage.removeItem('redirectTo');
        if(redirectUrl.ts > Date.now() - 5*7000){
          setState({
            redirectTo: redirectUrl.url
          });
        }
      }catch(err){
        localStorage.removeItem('redirectTo');
      }
    }

    if(allowedAppsData.length === 1 && allowedAppsData[0].url === c.SCREEN_APP_URL){
      setState({
        redirectTo: allowedAppsData[0].url
      })
    }
  };

  const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i += 1) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };

  const updateUserNames = (users) => {
    setState({
      userNames: users
    });
  };

  if (redirectTo) {
    window.location.replace(redirectTo);
    return <></>;
  }

  return (
    <div style={{height: "100%"}}>
      <StyledHeaderContainer id="header-container" className="header">
        <HeaderContainer
          signOut={signOut}
          history={history}
          isAdmin={isAdmin}
          showProfile={showProfile}
          feedbackUrl={apps.feedback.url}
          isFeedbackAdmin={isFeedbackAdmin}
        />
      </StyledHeaderContainer>
      <Container className="op-main-container">
        <Router history={history}>
          <Switch>
            <Route exact path="/">
              <StyledH2>
                👋 Welcome,{' '}
                {authData.signInUserSession.idToken.payload?.given_name ||
                  authData.signInUserSession.idToken.payload['email']}
                !
              </StyledH2>
              <AppSelector allowedApps={allowedApps} history={history} />
            </Route>
            <PrivateRoute exact path="/admin/users">
              <UsersList showToast={showToast} updateUsers={updateUserNames} />
            </PrivateRoute>
            <PrivateRoute exact path="/admin/users/add">
              <AddUsers showToast={showToast} userNames={userNames} />
            </PrivateRoute>
            <Route exact path="/profile">
              <Profile showToast={showToast} history={history} />
            </Route>
            <Route render={(props) => <Redirect to="/" {...props} />} />
          </Switch>
        </Router>
      </Container>
    </div>
  );
};

export default Dashboard;
