import React from 'react';
import { SignIn } from 'aws-amplify-react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components/macro';
import { getParameterByName } from '../../utils/helpers';
import { constants as c } from '../../utils/constants';
import { withStyles } from '@material-ui/core/styles';
import AuthLayout from '../../containers/Auth/AuthLayout';
import './AuthComponents.scss';
import { Auth } from 'aws-amplify';
import Loader from '../Loader/Loader';

const StyledBackdrop = withStyles({
  root: {
    zIndex: 10,
    color: '#fff'
  }
})(Backdrop);

const FedBtn = styled.div`
  border-color: black;
  border-width: 1px;
  border: 1px solid lightgrey;

  width: 100%;
  display: flex;
  cursor: pointer;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  height: 50px;

  border-radius: 6px;
  &:hover {
    background-color: lightgrey;
  }
`;

const SignInText = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  font-size: .9em;
  }    
`;

const TextInput = styled.input`
  width: 94%;
  height: 50px;
  display: flex;
  padding-left: 1em;
  border: 1px solid lightgrey;
  justify-content: center;
  border-radius: 6px;
  font-size: inherit;
  box-sizing: initial;
  &:focus {
    border: 1px solid #6d9eeb;
    outline: none;
  }
`;

const LoginButton = styled.div`
  background-color: #F78D34;
  width: 100%;
  height: 50px;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5em;
  cursor: pointer;
  &:hover {
    background-color: #F3BF64;
  }
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Link = styled.a`
  color: #6d9eeb;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const FormColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

class LoginComponent extends SignIn {
  constructor(props) {
    super(props);

    const authCode = getParameterByName('code');
    const federatedSignIn = getParameterByName('federatedSignIn');

    if (federatedSignIn) {
      this.setState({ loading: true });
      let redirect = getParameterByName('redirectTo');
      const qsVal = getParameterByName('qs');
  
      try {
        if (qsVal) {
          redirect = redirect + '?' + decodeURIComponent(qsVal)
        }
      } catch (err) {
        console.log('failed to parse query string value')
      }
  
  
      const path = window.location.pathname;
  
      if (redirect) {
        localStorage.setItem('redirectTo', JSON.stringify({ url: redirect, ts: Date.now() }));
      }
  
      if (path && path !== '/') {
        localStorage.setItem('path', JSON.stringify({ path, ts: Date.now() }));
      }
  
      Auth.federatedSignIn({ provider: federatedSignIn })
        .then((cred) => {
          this.setState({ loading: false });
        })
        .then((user) => {
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log('Err');
          console.log(err);
        });
    }

    if (process.env.NODE_ENV === 'development') {
      window.LOG_LEVEL = 'DEBUG';

      const resetUserAttribute = async (attr) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        Auth.updateUserAttributes(currentUser, {
          [attr]: ''
        });
      };

      window.resetUserAttribute = resetUserAttribute;
      window.IDPOOL_ID_ATTR = c.IDPOOL_ID_ATTR;
    }

    this.state.loading = federatedSignIn || authCode ? true : false;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.inputs = {
      username: '',
      password: ''
    };
  }

  checkFieldsFilled() {
    const filled = this.inputs.username && this.inputs.password;
    this.setState({
      fieldsFilled: filled
    });
  }

  handleInputChange(e) {
    this.inputs[e.target.id] = e.target.value;
  }

  federatedSignIn = (provider) => {
    this.setState({ loading: true });
    let redirect = getParameterByName('redirectTo');
    const qsVal = getParameterByName('qs');

    try {
      if (qsVal) {
        redirect = redirect + '?' + decodeURIComponent(qsVal)
      }
    } catch (err) {
      console.log('failed to parse query string value')
    }


    const path = window.location.pathname;

    if (redirect) {
      localStorage.setItem('redirectTo', JSON.stringify({ url: redirect, ts: Date.now() }));
    }

    if (path && path !== '/') {
      localStorage.setItem('path', JSON.stringify({ path, ts: Date.now() }));
    }

    Auth.federatedSignIn({ provider: provider })
      .then((cred) => {
        this.setState({ loading: false });
      })
      .then((user) => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log('Err');
        console.log(err);
      });
  };

  showComponent() {
    return (
      <AuthLayout>
        <Loader open={this.state.loading} />
        <FormColumn>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '336px',
              width: '100%',
              justifyContent: 'center'
            }}>
            <Row>
              <div id="odysseyLogo" style={{ backgroundImage: `url(${process.env.REACT_APP_env_img_base_path}logo_catalyte.png)` }} />
            </Row>
            <FedBtn onClick={() => this.federatedSignIn('Google')}>
              <div style={{ backgroundImage: `url(${process.env.REACT_APP_env_img_base_path}logo_google.svg)`, margin: '10px' }} id="googLogo" />
              <SignInText>Continue with Google</SignInText>
            </FedBtn>
            <div>
              <TextInput
                type="text"
                id="username"
                placeholder="email"
                onChange={(e) => {
                  this.handleInputChange(e);
                  this.checkFieldsFilled();
                }}
              />
            </div>
            <div style={{ marginTop: '.5em', justifyContent: 'center' }}>
              <TextInput
                type="password"
                id="password"
                placeholder="password"
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && this.state.fieldsFilled) {
                    this.signIn();
                  }
                }}
                onChange={(e) => {
                  this.handleInputChange(e);
                  this.checkFieldsFilled();
                }}
              />
            </div>
            <div>
              <LoginButton
                disabled={!this.state.fieldsFilled}
                text="Sign In"
                onClick={() => this.signIn()}>
                Log In
              </LoginButton>
            </div>
            <Row style={{ marginTop: '1em' }}>
              <Link
                style={{ marginRight: '.5em' }}
                className="op-logincomponent-backlink"
                onClick={() => {
                  this.changeState('forgotPassword');
                }}>
                Forgot Password?
              </Link>
            </Row>
            <StyledBackdrop open={this.state.loading || false}>
              <CircularProgress color="inherit" />
            </StyledBackdrop>
          </div>
        </FormColumn>
      </AuthLayout>
    );
  }
}

export default LoginComponent;
